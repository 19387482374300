@import '../styles';

.react-daterange-picker {
  display: inline-flex;
  position: relative;
  font-family: 'Roboto', 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: color(grey, main);

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--disabled {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: 1.5px solid color(grey, dark);
    border-radius: 6px;
    padding: 4px 8px;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: color(blue, royal);

      .react-daterange-picker__button__icon {
        color: color(blue, royal);
      }
    }
  }

  &__inputGroup {
    $digit-width: 0.3em;
    $dot-width: 0.217em;
    // own padding + inputs padding + digits width + dots width
    min-width: calc((4px * 3) + #{$digit-width} * 8 + #{$dot-width} * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: $digit-width;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;
      outline: none;

      &::#{'-webkit-outer-adjust-hue-button'},
      &::#{'-webkit-inner-adjust-hue-button'} {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: opacify(red, 0.1);
      }

      &--hasLeadingZero {
        margin-left: -$digit-width;
        padding-left: calc(1px + #{$digit-width});
      }
    }
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 2px 6px 12px;

    &:enabled {
      cursor: pointer;
    }

    &:disabled {
      .react-daterange-picker__button__icon {
        color: color(grey, main);
      }
    }

    svg {
      color: color(grey, main);
      display: inherit;
      width: 20px;
      height: 20px;
    }
  }

  &__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    margin-top: 4px;

    &--closed {
      display: none;
    }

    .react-calendar {
      border-width: thin;
    }
  }
}
