/*------------------------------------------------------*/
/*  COLORS */
/*------------------------------------------------------*/

$colors: (
  /*
  blue: (
    main: #000078,
    light: #d6ddf8,
    lighter: #e2e7fa,
    royal: #3455db,
    dark: rgb(0, 0, 120),
  ),
  */
  blue: (
    main: #C01722,
    light: #FF7276,
    lighter: #FFa7a6,
    royal: #C01722,
    dark: #C01722 //rgb(0, 0, 120),
  ),
  cyan: (
    main: #29e7d6,
  ),
  grey: (
    dark: #b2b4b6,
    light: #f2f2f2,
    main: #393939,
    medium: #a5a5a5,
  ),
  orange: (
    main: #df7a39,
    office365: #eb3c01,
  ),
  red: (
    main: #f13030,
  ),
  white: (
    main: #ffffff,
    mainBg: #fafafa,
    op50: rgba(255, 255, 255, 0.5),
    op95: rgba(255, 255, 255, 0.95),
  ),
);

@each $color, $tones in $colors {
  .background-color__#{'' + $color} {
    background-color: map-get($tones, 'base');

    @each $tone, $colorTone in $tones {
      &__#{'' + $tone} {
        background-color: $colorTone;
      }
    }
  }
}
