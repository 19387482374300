@import 'src/sass/styles';

.checkbox__symbol {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.checkbox__container {
  box-sizing: border-box;
  background: #ffffff;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  & * {
    box-sizing: border-box;
  }
}

.checkbox__input {
  position: absolute;
  visibility: hidden;

  &:checked + .checkbox span:first-child {
    background: color(blue, royal);
    border-color: color(blue, royal);
    animation: zoom-in-out 0.3s ease;

    & svg {
      stroke-dashoffset: 0;
    }
  }

  &:disabled {
    & + .checkbox:hover {
      background: transparent;
      cursor: default;
    }

    & + .checkbox span:first-child,
    &:checked + .checkbox span:first-child {
      border: 2px solid color(gray, dark);
    }

    &:checked + .checkbox span:first-child {
      background-color: color(gray, dark);
    }
  }
}

.checkbox {
  user-select: none;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:hover {
    background: rgba(color(blue, royal), 0.1);
    cursor: pointer;

    & span:first-child {
      border-color: color(blue, royal);
    }
  }

  & span {
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      flex: 0 0 18px;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      transform: scale(1);
      border: 2px solid color(blue, royal);
      transition: all 0.3s ease;

      & svg {
        position: absolute;
        top: 2px;
        left: 1px;
        fill: none;
        stroke: #fff;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transform: translate3d(0, 0, 0);
      }
    }

    &:last-child {
      padding-left: 8px;
      line-height: 18px;
    }
  }
}

@keyframes zoom-in-out {
  50% {
    transform: scale(0.9);
  }
}
