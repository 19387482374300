@import '../styles';

.react-calendar.calendar-picker {
  --day-spacing-ratio: 4px;
  --day-margin: calc(var(--day-spacing-ratio) + 4px);
  --label-font-size: 0.85rem;

  &:not(&-flyout) {
    --day-spacing-ratio: 8px;
    --label-font-size: 1rem;
  }

  width: 100%;
  border: none;
  line-height: 1.125em;
  padding: 8px;

  &-flyout {
    box-shadow: 0 0 4px 0 rgba(color(grey, dark), 0.4);
  }

  & * {
    font-family: 'Roboto', 'Roboto Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  }

  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: none;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 8px;
  }
  .react-calendar__navigation button {
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: color(grey, dark);
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75em;
    background-color: color(grey, light);
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    & > abbr {
      font-weight: normal;
      text-decoration: unset;
      text-transform: none;
      color: color(grey, medium);
    }
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    position: relative;
    // to impose weekend grey background to behind the day when selected
    transform-style: preserve-3d;
    max-width: 100%;
    padding: 0;
    border-radius: 6px;
    padding-block: var(--day-spacing-ratio);
    margin-block: var(--day-margin);
    background: none;
    text-align: center;
    overflow: visible !important;

    &.react-calendar__month-view__days__day {
      $day-size: percentage(calc(1 / 7));
      flex-basis: calc(#{$day-size} - (var(--day-margin) * 2)) !important;
      margin-inline: var(--day-margin);
    }
  }

  .react-calendar__tile:disabled {
    background-color: white;
    color: color(grey, medium) !important;
  }

  .react-calendar__tile:enabled:hover {
    background-color: color(blue, light);
  }

  .react-calendar__tile--range {
    background: color(blue, light) !important;
    color: color(grey, main) !important;

    &.react-calendar__month-view__days__day--weekend {
      background: transparent !important;
    }
  }

  .react-calendar__tile--hover.react-calendar__month-view__days__day--weekend {
    background: transparent !important;
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: color(blue, royal) !important;
    color: white !important;
  }

  .react-calendar__tile:enabled:focus,
  .react-calendar__tile--rangeStart:enabled:focus,
  .react-calendar__tile--rangeEnd:enabled:focus {
    background: color(blue, royal) !important;
    color: white !important;
  }

  .react-calendar__tile--now {
    color: color(blue, royal);
    font-weight: 600;
  }

  .react-calendar__tile--now:enabled:focus {
    background: color(blue, royal) !important;
    color: white !important;
  }

  .react-calendar__month-view__days__day--weekend {
    &::before {
      content: '';
      position: absolute;
      top: calc(var(--day-margin) * -1);
      left: calc(var(--day-margin) * -1);
      width: calc(100% + var(--day-margin) * 2);
      height: calc(100% + var(--day-margin) * 2);
      // to impose weekend grey background to behind the day when selected
      transform: translate3d(0, 0, -1px);
      background-color: color(grey, light);
    }
    color: color(grey, main) !important;

    &.react-calendar__tile--rangeStart,
    &.react-calendar__tile--rangeEnd {
      background-color: color(blue, royal) !important;
      color: white !important;
    }
  }

  .react-calendar__month-view__days__day--weekend:disabled {
    background-color: color(grey, light) !important;
  }

  .react-calendar__tile--hasActive {
    background: color(blue, royal);
    color: white;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: color(blue, royal) !important;
    color: white !important;
  }
  .react-calendar__tile--active {
    background: color(blue, main);
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: color(blue, lighter);
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: color(blue, lighter) !important;
  }
  .react-calendar__tile--hover {
    background-color: color(blue, lighter) !important;
  }

  .react-calendar__navigation {
    &__prev2-button,
    &__next2-button {
      display: none;
    }

    button:disabled {
      color: color(grey, light);
      background: transparent;
    }

    button:hover:not(:disabled) {
      color: color(blue, royal);
      background: transparent;
    }

    &__label {
      text-align: left;
      padding-left: 8px;
      color: color(blue, royal);
      font-weight: normal;
      font-size: var(--label-font-size);

      &:enabled:focus,
      &:enabled:hover {
        background-color: transparent;
      }
    }

    &__prev-button,
    &__next-button {
      padding: 0;
      min-width: 24px;
      color: color(grey, main);
    }

    &__prev-button {
      order: 1;
    }

    &__next-button {
      order: 2;
      margin-left: 16px;
    }
  }
}
