@import 'src/sass/styles';

$fonts: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

mgt-people-picker {
  --default-font-family: $fonts;
  --input-border: 0px;
  --font-weight: 400;
  --selected-person-background-color: transparent;
}

.people-picker-input-disabled {
  --input-background-color: transparent;
  --placeholder-color: #{color(grey, dark)};
  background: color(grey, light);
}

.people-picker-input-error {
  border-color: color(red, main) !important;
}

.chip {
  font-size: 14px;
  padding: 0 12px 0 6px;
}

.people-picker-input-wrapper {
  border-radius: 6px;
  border: 1px solid color(grey, dark);
  padding: 2px 2px;

  &:focus-within {
    border-color: color(blue, main);
  }
}

.overlappedText {
  margin: auto;
  margin-top: 3px;
  display: flex;
  margin-left: 3px;
}

.overlappedErrorIcon {
  height: 18px;
  width: 18px;
  margin-top: 4px;
  margin-right: 3px;
  color: color(red, main);
}

.self-person__wrapper {
  padding: 6px 0 0;

  & mgt-person {
    --avatar-size: 0px;
  }
}

.self-person__content {
  margin: 1px 2px 8px;
}

.self-person__name {
  font-size: 14px;
  margin: 0 0 0 8px;
  color: color(grey, dark);
}
